<template>
  <div class="home">
    <section class="hero">
      <div class="hero-slide-1 vh-100">
        <div class="px-5 h-100 container d-flex align-items-center">
          <div class="pt-3">
            <h1 class="display-1 fw-bold v-text text-white pt-5 pb-4 text-shadow">
              the ultimate Quad Biking experience
            </h1>
            <h2 class="v-text pb-3 text-white text-shadow h4">
              Experience The Garden Route like never before, with spectacular
              views and exciting tracks. It will be a memory you will never
              forget.
            </h2>
            <a href="https://wa.me/+27729433616" target="_blank" class="
                btn btn-lg btn-blue
                h5
                v-text
                text-uppercase text-shadow
                shadow
                px-5
              ">Book Now!</a>
          </div>
        </div>
      </div>
    </section>

    <main class="">
      <section class="container">
        <div class="row text-center pt-lrg pb-lrg">

          <div class="col-12 pb-5">
            <h2 class="pb-2 h2 v-text text-uppercase ">Welcome to the Garden Route's premier destination for thrilling
              outdoor fun!
            </h2>
            <p class="pb-5 h5">Our Quad Bike
              and
              Paintball
              business is perfectly nestled between the picturesque towns of Knysna and Plettenberg Bay, making it a
              convenient and exciting getaway for families and groups alike.
            </p>
          </div>
          <div class="col-md-4">
            <img src="../assets/professional.svg" class="img-fluid home-icons" alt="" />
            <h4 class="v-text text-uppercase pb-3 pt-4">Professional Guides</h4>
            <p>
              Our club has the most experienced Guides to help you get the most
              out of your experience.
            </p>
          </div>
          <div class="col-md-4">
            <img src="../assets/location.svg" class="img-fluid home-icons" alt="" />
            <h4 class="v-text text-uppercase pb-3 pt-4">Best Location</h4>
            <p>
              Quadbiking at a breathtaking spot, which has been a prime location
              for many years.
            </p>
          </div>
          <div class="col-md-4">
            <img src="../assets/safety.svg" class="img-fluid home-icons" alt="" />
            <h4 class="v-text text-uppercase pb-3 pt-4">Safety First</h4>
            <p>
              We offer a range of both beginner and pro equipment, including
              safe helmets and more!
            </p>
          </div>
        </div>
      </section>
      <section class="hero-slide-2">
        <div class="container">
          <div class="row pt-lrg pb-lrg">
            <div class="col-md-12">
              <h2 class="
                  display-2
                  fw-bold
                  v-text
                  text-white text-center
                  pt-xl
                  text-shadow
                ">
                What We Offer
              </h2>
              <h4 class="text-white text-md-center text-shadow">
                At Adventure Garden Route, we offer a variety of adventures.
                Below are a few of our most popular offers that we hope will
                interest you.
              </h4>
            </div>
            <div class="col-md-12">
              <h3 class="
                  fw-bold
                  v-text
                  text-white text-center
                  pt-5
                  text-shadow
                ">
                Quad Bike Adventures
              </h3>
              <h5 class="text-white text-md-center text-shadow">
                Discover the Garden Route's breathtaking landscapes from a whole new perspective as you embark on an
                exhilarating quad biking adventure. Our professionally designed tracks are sure to get your heart racing
                as you navigate the twists, turns, and open trails. Whether you're an experienced rider or a novice, we
                offer a range of quad bikes suitable for all skill levels. Families can even bring the kids along, as we
                have specially designed kids' bikes for their enjoyment. Our quad bike experiences provide a perfect mix
                of adrenaline and natural beauty, making it an ideal activity for nature enthusiasts and thrill-seekers
                alike.
              </h5>
            </div>
            <div class="col-md-12">
              <h3 class="
                  fw-bold
                  v-text
                  text-white text-center
                  pt-5
                  text-shadow
                ">
                Paintball Excitement
              </h3>
              <h5 class="text-white text-md-center text-shadow">
                For those who prefer strategy and teamwork, our paintball arena promises hours of thrilling action. Gather
                your friends and family, form teams, and engage in adrenaline-pumping battles in a lush and wooded
                environment. Our well-maintained paintball field offers plenty of cover and obstacles, allowing for
                strategic gameplay. It's an excellent way to bond, strategize, and have a blast with your group.

              </h5>
            </div>

          </div>
        </div>
        <div class="container-fluid">
          <div class="row pt-lrg pb-lrg">
            <div class="card-group mt-lrg">
              <div class="card home-card">
                <img src="../assets/quad-service.webp" class="card-img-top image-bw"
                  alt="Garden Route Quad Bikes - Quadbiking" />
                <div class="card-body text-center">
                  <h4 class="card-title v-text text-uppercase pb-3">
                    QuadBiking
                  </h4>
                  <h5 class="card-text text-red pb-3">From R350</h5>
                  <a href="https://wa.me/+27729433616?text=Hi%0aI%20am%20interested%20in%20quad%20biking" target="_blank"
                    class="
                      btn btn-lg btn-blue-border
                      h5
                      v-text
                      text-uppercase text-shadow
                      px-5
                    ">Book Now!</a>
                </div>
              </div>
              <div class="card home-card">
                <img src="../assets/quad-kids.webp" class="card-img-top image-bw"
                  alt="Garden Route Quad Bikes - Quadbiking" />
                <div class="card-body text-center">
                  <h4 class="card-title v-text text-uppercase pb-3">
                    QuadBiking <span class="text-transform">Kids</span>
                  </h4>
                  <h5 class="card-text text-red pb-3">From R150</h5>
                  <a href="https://wa.me/+27729433616?text=Hi%0aI%20am%20interested%20in%20quad%20biking%20kids"
                    target="_blank" class="
                      btn btn-lg btn-blue-border
                      h5
                      v-text
                      text-uppercase text-shadow
                      px-5
                    ">Book Now!</a>
                </div>
              </div>
              <div class="card home-card">
                <img src="../assets/paintball.webp" class="card-img-top image-bw"
                  alt="Garden Route Quad Bikes - Sandboarding" />
                <div class="card-body text-center">
                  <h4 class="card-title v-text text-uppercase pb-3">Paintball</h4>
                  <h5 class="card-text text-red pb-3">From R150pp</h5>
                  <a href="https://wa.me/+27729433616?text=Hi%0aI%20am%20interested%20in%20paintbal" target="_blank"
                    class="
                      btn btn-lg btn-blue-border
                      h5
                      v-text
                      text-uppercase text-shadow
                      px-5
                    ">Book Now!</a>
                </div>
              </div>
              <!-- <div class="card home-card">
                <img src="../assets/buggie.webp" class="card-img-top image-bw" />
                <div class="card-body text-center">
                  <h4 class="card-title v-text text-uppercase pb-3">Buggy</h4>
                  <h5 class="card-text text-red pb-3">TBC</h5>
                  <a href="https://wa.me/+27729433616?text=Hi%0aI%20am%20interested%20in%20Buggy" target="_blank" class="
                      btn btn-lg btn-blue-border
                      h5
                      v-text
                      text-uppercase text-shadow
                      px-5
                    ">Book Now!</a>
                </div>
              </div> -->
              <!-- <div class="card home-card">
                <img src="../assets/obstical.webp" class="card-img-top image-bw" />
                <div class="card-body text-center">
                  <h4 class="card-title v-text text-uppercase pb-3">Obstical Course</h4>
                  <h5 class="card-text text-red pb-3">TBC</h5>
                  <a href="https://wa.me/+27729433616?text=Hi%0aI%20am%20interested%20in%20Obstical%20Course"
                    target="_blank" class="
                      btn btn-lg btn-blue-border
                      h5
                      v-text
                      text-uppercase text-shadow
                      px-5
                    ">Book Now!</a>
                </div>
              </div> -->

            </div>
          </div>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-md-12
                  pb-xl">
              <h2 class="
                  display-2
                  fw-bold
                  v-text
                  text-white text-center
                  pb-5
                  text-shadow
                ">
                Why Choose Us
              </h2>
              <h5 class="text-white text-shadow pb-4">
                Convenient Location: Our business is strategically situated between Knysna and Plettenberg Bay, making
                it
                easily accessible for locals and tourists alike.
              </h5>
              <h5 class="text-white text-shadow pb-4">
                Exciting Tracks and Ride-Alongs: Our quad biking tracks are carefully designed to provide an exciting
                and
                safe adventure for all skill levels. Whether you're seeking speed or a leisurely ride, we have something
                for
                everyone.
              </h5>
              <h5 class="text-white text-shadow pb-4">
                Family-Friendly: We cater to families and groups, ensuring a fantastic experience for all ages. Our
                kids'
                bikes make it possible for even the youngest adventurers to participate.
                Thrilling Paintball: Our paintball arena is designed for thrilling, team-based action. It's a perfect
                activity for bonding, strategizing, and getting the heart pumping.

              </h5>
              <h5 class="text-white text-shadow">
                Experience the Garden Route in a whole new way with our quad bike and paintball adventures. Whether
                you're
                looking for family-friendly fun or an adrenaline rush with friends, we've got it all covered. Join us
                today
                for an unforgettable outdoor experience that combines nature's beauty with excitement and camaraderie.
              </h5>
            </div>
          </div>
        </div>
      </section>
      <!-- <section>
        <div class="row">
          <div class="col-md-6">
            <h2 class="pb-5 text-red v-text">Welcome to Adventure Garden Route</h2>
            <p>
              From the natural wonder that is Table Mountain to awe-inspiring
              desert landscapes and Precambrian limestone caves.
            </p>
            <p>
              Home to mountains, oceans, beautiful nature reserves and
              vineyards, Garden Route is the ideal spot for some of the world’s
              most unique and popular adventure activities. Discover a world of
              unique experiences and adrenaline fuelled adventures, and be
              inspired by the diversity of culture, cuisine, wine and wildlife.
              From tours and tastings at the most elegant wine farms, to
              adrenaline pumping quad bike rides in majestic, powdery dunes - we
              have you covered!
            </p>
            <p>
              Visit the Big 5, go sand boarding, paraglide over Garden Route for
              the best views imaginable, explore some of the best craft beer and
              gin distilleries, go shark cage diving, hike to natural pools
              where you can cliff jump into crystal waters, horseback ride along
              an endless coast, walk with lions or feed elephants, the list goes
              on and on and on. With almost two decades experience, Adventure
              Garden Route will ensure your craving for adventure is satisfied!
            </p>
            <p>Why go with the rest when you can go with the best ;)</p>
          </div>
          <div class="col-md-6 d-flex align-items-center">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/_V_wr1xh5ag"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay;  picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </section> -->
    </main>
  </div>
</template>

<script>
// import { Hooper, Slide, Navigation as HooperNavigation } from "hooper";
// import "hooper/dist/hooper.css";

export default {
  components: {
    // Hooper,
    // Slide,
    // HooperNavigation,
  },
  data() {
    return {};
  },
};
</script>
