<template>
  <div class="safety">
    <section class="hero">
      <div class="hero-slide-1 vh-100">
        <div class="px-5 h-100 container d-flex align-items-center text-center">
          <div class="pt-xl">
            <h1 class="display-1 fw-bold v-text text-white pt-xl text-shadow">
              relax knowing you are with the best instructors and guides
            </h1>

            <a
              href="https://wa.me/+27823792293"
              target="_blank"
              class="
                btn btn-lg btn-blue
                h5
                v-text
                text-uppercase text-shadow
                shadow
                px-5
                mt-5
              "
              >Book Now!</a
            >
          </div>
        </div>
      </div>
    </section>

    <main class="">
      <section class="container">
        <div class="row pt-lrg pb-lrg">
          <div class="col-md-12 pt-5">
            <h2 class="v-text pb-5">Safety and Rules</h2>
          </div>
          <div class="col-12">
            <div class="row">
              <div class="col-md-4 pb-5 card border-0">
                <h5 class="v-text text-blue card-header bg-transparent">
                  <span class="text-dark">1.</span> Ensure that you follow the
                  tracks of the guide
                </h5>
                <div class="card-body">
                  <p class="card-text">
                    The dunes change every day and this is why we have a guide
                    who surveys the area up ahead for your safety. Most of the
                    dunes you will be riding have a soft side and a steep side,
                    and if you follow the guide then he will not go through
                    areas which may cause you to fall and injure yourself. Many
                    of the dunes have nearly sheer drops, and simply being
                    marginally off track can be the difference between a smooth
                    drop and a vertical one which may cause serious injury.
                  </p>
                </div>
              </div>
              <div class="col-md-4 pb-5 card border-0">
                <h5 class="v-text text-blue card-header bg-transparent">
                  <span class="text-dark">2.</span> Keep a safe following
                  distance of 5m
                </h5>
                <div class="card-body">
                  <p class="card-text">
                    Quad bikes are made to ride on the sand which is very
                    different from driving on the road. Steering is heavy and
                    braking very sharp as the weight of the sand strongly
                    impedes on the tyres. For this reason we ask that you keep a
                    5m following distance between you and the person in front
                    you at all times. This allows for sufficient reactionary
                    time should the person ahead of you experience trouble.
                    Maintaining this distance also stops you from colliding into
                    the person in front of you, which usually results in injury
                    to both parties, or damages to the quad bike for which you
                    shall be held liable in the event of your negligence.
                  </p>
                </div>
              </div>
              <div class="col-md-4 pb-5 card border-0">
                <h5 class="v-text text-blue card-header bg-transparent">
                  <span class="text-dark">3.</span> Watch what the person in
                  front of you is doing
                </h5>
                <div class="card-body">
                  <p class="card-text">
                    Whilst driving, you need to be vigilant as to what is
                    happening in front of you. Should the guide slow down, it is
                    usually because of the terrain conditions or the following
                    riders' skills prevent him from following his conventional
                    path, at which point he will be standing on his bike.
                    Usually the front bike has a flag to indicate what the
                    terrain is doing and when there are sharp drops ahead.
                    Paying attention will ensure your safety and that of others.
                  </p>
                </div>
              </div>
              <div class="col-md-4 pb-5 card border-0">
                <h5 class="v-text text-blue card-header bg-transparent">
                  <span class="text-dark">4.</span> If one person stops, we all
                  stop
                </h5>
                <div class="card-body">
                  <p class="card-text">
                    Should anyone experience a problem, we need to stop and
                    attempt to solve the issue, so that everyone gets the best
                    experience. We always keep everyone within safe visual
                    distance, and in larger groups we split the group at times
                    by placing an extra guide in the middle for your safety.
                  </p>
                </div>
              </div>
              <div class="col-md-4 pb-5 card border-0">
                <h5 class="v-text text-blue card-header bg-transparent">
                  <span class="text-dark">5.</span> No reckless driving
                </h5>
                <div class="card-body">
                  <p class="card-text">
                    Irrespective of your skill level, you need to remember that
                    we are responsible for your safety. It must be noted that
                    this is a guided tour and not a joyride, so we request that
                    you to drive in an orderly fashion. You will be held liable
                    for any damages to the quad bike in the event of your
                    negligence and failure to comply with these rules.
                  </p>
                </div>
              </div>
              <div class="col-md-4 pb-5 card border-0">
                <h5 class="v-text text-blue card-header bg-transparent">
                  <span class="text-dark">6.</span> Always listen to the guides
                </h5>
                <div class="card-body">
                  <p class="card-text">
                    The guides represent the owner of the company, and have
                    years of individual experience in the dunes. At every moment
                    that you are on these dunes and on one of our bikes, they
                    are responsible for the safety of you and the quad bike. It
                    is therefore imperative that you listen to the guides
                    attentively and if you're not clear on any issue, ask for
                    further clarification.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  </div>
</template>

<script>
// import { Hooper, Slide, Navigation as HooperNavigation } from "hooper";
// import "hooper/dist/hooper.css";

export default {
  components: {
    // Hooper,
    // Slide,
    // HooperNavigation,
  },
  data() {
    return {};
  },
};
</script>
