<template>
  <div class="price">
    <section class="hero">
      <div class="hero-slide-1 vh-100">
        <div class="px-5 h-100 container d-flex align-items-center text-center">
          <div class="pt-xl">
            <h1 class="display-1 fw-bold v-text text-white pt-xl text-shadow">
              The Garden Route adventure you deserve!
            </h1>

            <a href="https://wa.me/+27729433616" target="_blank" class="
                btn btn-lg btn-blue
                h5
                v-text
                text-uppercase text-shadow
                shadow
                px-5
                mt-5
              ">Book Now!</a>
          </div>
        </div>
      </div>
    </section>

    <main class="">
      <section class="container">
        <div class="row pt-lrg pb-lrg">
          <div class="col-md-12 pt-5">
            <h2 class="v-text pb-5 text-center h2">Quad Bike Pricing </h2>
            <p class="text-center">
              We currently have several of our popular services listed below. If
              you planning a children's birthday party, corporate event, or have
              larger groups of people wishing to experience a tailored event please <router-link to="contact"
                class="text-decoration-none fw-bold">Contact Us Here.</router-link>
            </p>
          </div>
          <!-- pricing table  -->
          <div id="pricing-tables col-md-12">
            <div class="row d-flex justify-content-center">
              <div class="col-lg-3 col-md-6 col-xs-12 mb-4" v-for="(trip, index) in trips" :key="index">
                <div class="single-table text-center">
                  <div class="plan-header">
                    <h3 class="v-text h4">{{ trip.title }}</h3>
                    <h4 class="plan-price">
                      {{ trip.price }}<span>/p.p</span>
                    </h4>
                  </div>

                  <ul class="plan-features">
                    <li>{{ trip.item1 }}</li>
                    <li>{{ trip.item2 }}</li>
                    <li>{{ trip.item3 }}</li>
                    <li>{{ trip.item4 }}</li>
                    <li>{{ trip.time }}</li>
                  </ul>
                  <a href="https://wa.me/+27729433616" target="_blank" class="plan-submit hvr-bounce-to-right">Book
                    Now</a>
                </div>
              </div>
            </div>
          </div>
          <div class="pt-lrg">
            <h2 class="v-text pb-5 text-center h2">Paintball Pricing </h2>
          </div>
          <!-- pricing table  -->
          <div id="pricing-tables col-md-12">
            <div class="row d-flex justify-content-center">
              <div class="col-lg-3 col-md-6 col-xs-12 mb-4" v-for="(trip, index) in others" :key="index">
                <div class="single-table text-center">
                  <div class="plan-header">
                    <h3 class="v-text h4">{{ trip.title }}</h3>
                    <h4 class="plan-price">
                      {{ trip.price }}<span>/p.p</span>
                    </h4>
                  </div>

                  <ul class="plan-features">
                    <li>{{ trip.item1 }}</li>
                    <li>{{ trip.item2 }}</li>
                    <li>{{ trip.item3 }}</li>
                    <li>{{ trip.item4 }}</li>
                    <li>{{ trip.time }}</li>
                  </ul>
                  <a href="https://wa.me/+27729433616" target="_blank" class="plan-submit hvr-bounce-to-right">Book
                    Now</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <p>
            * Extra hours available at discounted rates after initial session.
            If you would like to plan a larger group or kids birthday parties,
            please contact us here
          </p>
          <!-- <p>
            *Transport to/from your accommodation to the dunes can be provided
            at an additional R300 per person. For organised groups of five
            people or more, a discounted rate of R220 per person will apply.
          </p> -->
        </div>
      </section>
    </main>
  </div>
</template>

<script>
// import { Hooper, Slide, Navigation as HooperNavigation } from "hooper";
// import "hooper/dist/hooper.css";

export default {
  components: {
    // Hooper,
    // Slide,
    // HooperNavigation,
  },
  data() {
    return {
      trips: [
        {
          title: "Quad Biking 4km",
          item1: "Standard Rides",
          item2: "Qualified Guides",
          item3: "Beginner and Advanced Riding Routes",
          item4: "All Equipment Provided",
          time: "4km",
          price: "R350",
        },
        {
          title: "Quad Biking 7km",
          item1: "Standard Rides",
          item2: "Qualified Guides",
          item3: "Beginner and Advanced Riding Routes",
          item4: "All Equipment Provided",
          time: "7km",
          price: "R450",
        },
        {
          title: "Quad Biking 11km",
          item1: "Standard Rides",
          item2: "Qualified Guides",
          item3: "Beginner and Advanced Riding Routes",
          item4: "All Equipment Provided",
          time: "11km",
          price: "R550",
        },
        {
          title: "Quad Biking 15km",
          item1: "Standard Rides",
          item2: "Qualified Guides",
          item3: "Beginner and Advanced Riding Routes",
          item4: "All Equipment Provided",
          time: "15km",
          price: "R650",
        },
        {
          title: "Extreme Ride",
          item1: "Standard Rides",
          item2: "Qualified Guides",
          item3: "Beginner and Advanced Riding Routes",
          item4: "All Equipment Provided",
          time: "12km",
          price: "R900",
        },
        {
          title: "Kids 2-6",
          item1: "Standard Rides",
          item2: "Qualified Guides",
          item3: "2 -6 or less than 30kg",
          item4: "All Equipment Provided",
          time: " Kids friendly",
          price: "R150",
        },
        // {
        //   title: "Buggies",
        //   item1: "Extreme Buggy Course",
        //   item2: "Qualified Guides",
        //   item3: "Designed For The Adventurous",
        //   item4: "All Equipment Provided",
        //   time: "25 Minutes",
        //   price: "TBC",
        // },
        // {
        //   title: "Combo 1",
        //   item1: "Sand Dune Rides",
        //   item2: "Sandboarding",
        //   item3: "Qualified Guides",
        //   item4: "All Equipment Provided",
        //   time: "3 Hour",
        //   price: "R1450",
        // },
        // {
        //   title: "Combo 2",
        //   item1: "Quad Biking",
        //   item2: "Paragliding",
        //   item3: "Qualified Guides/Instructors",
        //   item4: "All Equipment Provided",
        //   time: "3-4 Hour",
        //   price: "R2450",
        // },
      ],
      others: [
        {
          title: "Painball",
          item1: "100 paintball",
          item2: "Gun, mask, range, overalls",
          item3: "4x15 min sessions",
          item4: "All Equipment Provided",
          time: "R120 for an extra 100 paintballs",
          price: "R150",
        },
      ],
    };
  },
};
</script>
