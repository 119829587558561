<template>
  <div class="about">
    <section class="hero">
      <div class="hero-slide-1 vh-100">
        <div class="px-5 h-100 container d-flex align-items-center text-center">
          <div class="pt-xl">
            <h1 class="display-1 fw-bold v-text text-white pt-xl text-shadow">
              The best Quad Biking on The Garden Route!
            </h1>

            <a
              href="https://wa.me/+27823792293"
              target="_blank"
              class="
                btn btn-lg btn-blue
                h5
                v-text
                text-uppercase text-shadow
                shadow
                px-5
                mt-5
              "
              >Book Now!</a
            >
          </div>
        </div>
      </div>
    </section>

    <main class="">
      <section class="container">
        <div class="row pt-lrg pb-lrg">
          <div class="col-md-6">
            <img
              src="../assets/cape-town-quad-bikes-about-us.webp"
              class="img-fluid shadow rounded"
              alt=""
            />
          </div>
          <div class="col-md-6 pt-5">
            <h2 class="v-text pb-5">Welcome to Cape Town Quad Bikes</h2>
            <p>
              Cape Town is home to some of the most beautiful natural reserves.
              From mountains to oceans, it is ideal for some of the worlds most
              unique and popular adventure activities. One of the best ways to
              explore Cape Towns beautiful natural reserves is from the seat of
              a Quad Bike. Come play with us as our guide takes you on an
              exhilarating ride through the many sand dunes in the Atlantis
              Dunes Nature Reserve or tackle the twisting trails of sand and
              bush in the nearby areas of Melkbosstrand. For the extreme
              adrenaline junkies, we have our action packed sandboarding and
              Quad Bike Combo. Enjoy Cape town in this epic adventure day!
            </p>
          </div>
        </div>
      </section>
    </main>
  </div>
</template>

<script>
// import { Hooper, Slide, Navigation as HooperNavigation } from "hooper";
// import "hooper/dist/hooper.css";

export default {
  components: {
    // Hooper,
    // Slide,
    // HooperNavigation,
  },
  data() {
    return {};
  },
};
</script>
