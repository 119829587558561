<template>
  <div class="bg-blue pb-5">
    <!-- <iframe
      src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7615.760173123661!2d18.447333!3d-33.599056!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1dccede7da916bd1%3A0x85390a7c09b13b2!2sAtlantis%20Dunes!5e1!3m2!1sen!2sza!4v1656590115798!5m2!1sen!2sza"
      style="border: 0; height: 500px"
      allowfullscreen="yes"
      loading="lazy"
      class="w-100"
    ></iframe> -->

    <div class="container p-4 w-100 footer">
      <p class="text-center text-white text-uppercase">
        ADVENTURE Garden Route © {{ new Date().getFullYear() }}
      </p>
      <br />
      <p class="text-center text-white d-flex flex-md-row flex-column justify-content-md-center">
        <a href="mailto:info@adventuregardenroute.com" class="
            text-center text-white text-decoration-none
            pe-md-5
          "><i class="far fa-envelope"></i> info@adventuregardenroute.com</a>

        <a href="tel:+27729433616" class="text-center text-white text-decoration-none pe-md-5"><i
            class="fas fa-phone-alt"></i>
          072 943 3616</a>
        <a href="https://wa.me/+27729433616" target="_blank"
          class="text-center text-white text-decoration-none pe-md-5"><i class="fa-brands fa-whatsapp"></i> 072 943
          3616</a>
        <!-- 
        <a href="https://www.facebook.com/tourcapetown?ref=br_tf" class="text-center text-white text-decoration-none"><i
            class="fab fa-facebook-f pe-3"> </i></a>

        <a href="https://www.instagram.com/adventurecapetown" class="text-center text-white text-decoration-none"><i
            class="fab fa-instagram pe-3"> </i></a> -->

        <!-- <a href="https://www.tripadvisor.co.za/Attraction_Review-g312673-d21143929-Reviews-Cape_Town_Quad_Bikes-Stellenbosch_Western_Cape.html"
          class="text-center text-white text-uppercase text-decoration-none" target="_blank">Trip Advisor</a> -->
      </p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      date: null,
    };
  },
};
</script>
